import React from "react"
import { Container, Col, Row, Table, Image, Figure, Tab } from "react-bootstrap"

import Layout from "../../../components/layoutHome"
import SEO from "../../../components/seo"

const CapacitacionSitimm = ({ data }) => {
  const Model = data.ceformaPagesShow12.data.page.content
  return (
    <Layout>
      <SEO title={Model.title}/>
      <Container>
        <Row>
          <Col>
            <h1>{Model.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xl={2} md={3}>
            <Figure>
              <Figure.Image src={Model.PrincipalIMG} />
            </Figure>
          </Col>
          <Col xl={10} md={9}>
            <p>{Model.welcomeText}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              El presente programa de capacitación tiene las siguientes
              características.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <ol>
              {Model.characteristics.map(characteristic => (
                <li>{characteristic.characteristic}</li>
              ))}
            </ol>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container
              fluid
              style={{
                color: "white",
                backgroundColor: "#29333D",
                textAlign: "center",
              }}
            >
              <h3>
                De esta manera el programa general de Capacitación y Formación
                Sindical 2020 del Centro de Formación Laboral es el siguiente:
              </h3>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col 
           style={{
            maxWidth:"100%",
            overflow:"auto"
          }}
          >
            <Table>
              <thead>
                <tr>
                  <th>SEDE / FECHA</th>
                  <th>CURSO-TALLER Y/O SEMINARIO</th>
                  <th>TEMAS ABORDADOS</th>
                  <th>PARTICIPANTES</th>
                </tr>
              </thead>
              <tbody>
                {Model.calendar.map(element => (
                  <tr>
                    <td>{element.date}</td>
                    <td>{element.title}</td>
                    <td>{element.topics}</td>
                    <td>{element.participants}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{Model.banner1}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{Model.endtext}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <ol>
              {Model.bonus.map(bonus => (
                <li>{bonus.bonus}</li>
              ))}
            </ol>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default CapacitacionSitimm

export const query = graphql`
  query CapacitacionSitimmQuery {
    ceformaPagesShow12 {
      data {
        page {
          content {
            bonus {
              bonus
            }
            title
            banner1
            endtext
            calendar {
              date
              place
              title
              topics
              participants
            }
            welcomeText
            PrincipalIMG
            characteristics {
              characteristic
            }
          }
        }
      }
    }
  }
`
